import {required} from 'vuelidate/lib/validators'
import { mapGetters,} from 'vuex';

export default {
    props: ['popupType', 'selectedItem'],
    components:{
        listStatuses: ()=> import('../listStatuses/index.vue')
    },
    data() {
        return {
            form: {
                delivery_title: 'Delivery',
                delivery_module: 'Delivery',
                delivery_description: 'Delivery модуль',
                delivery_status: 1,
                delivery_list_matched_statuses: [{
                    crm_status:'',
                    order_status:''
                }],
            },
        }
    },

    validations: {
        form: {
            delivery_title: {
                required
            }
        }
    },
    computed: {
        ...mapGetters({
            options:'config/options',
        }),
    },
    created() {
        this.form.delivery_list_matched_statuses = this.options.models.delivery_list_matched_statuses ? this.options.models.delivery_list_matched_statuses :[{
            crm_status:'',
            order_status:''
        }];
    },
    methods: {
        closePopup() {
            this.$emit('closePopup')
        },
        addToStatusList(){
            this.form.delivery_list_matched_statuses.push({
                srm_status: '',
                order_status: ''
            })
        },
        removeStatusItem(item){
            const index = this.form.delivery_list_matched_statuses.indexOf(item);
            this.form.delivery_list_matched_statuses.splice(index, 1);
        },
        sendConfirm() {
            if(this.warehouse){
                this.$emit('changeOptions', this.form)
            }else{
                this.$emit('sendOptions', this.form)
            }
        },
    },
    destroyed() {
        this.changeShowWarehouse(null);
    }
}
